@use "../../../../colors.scss";

.industry-solutions {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: colors.$app-indigo;
    background-image: url("../../../../assets/images/footer_background.png");
    
    .content-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 1240px;
        padding: 90px 20px;

        @media (max-width: 1300px) {
            flex-direction: column;
            align-items: center;
            padding: 64px 20px;
            gap: 48px;
            width: 100%;
        }

        .title {
            font-size: 2.25rem;
            color: colors.$app-white;
            font-weight: 500;
            white-space: nowrap;
            width: 70%;

            @media (max-width: 1300px) {
                width: auto;
            }

            @media (max-width: 425px) {
                white-space: wrap;
                text-align: center;
            }
        }

        .cards-container {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            @media (max-width: 1280px) {
                max-width: 650px;
            }

            @media (max-width: 700px) {
                justify-content: center;
            }


            .card {
                width: 300px;
                box-shadow: #00000029 0px 4px 4px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 24px;
                border-radius: 8px;
                position: relative;
                background-color: colors.$app-white;

                @media (max-width: 425px) {
                    width: 100%;
                }


                &::before {
                    position: absolute;
                    content: '';
                    height: 4px;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background: linear-gradient(to right, colors.$app-blue, colors.$app-cyan);
                    border-top-left-radius: 32px;
                    border-top-right-radius: 32px;
                }

                .card-title {
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: colors.$app-black;
                    line-height: 1.75rem;

                }

                .card-description {
                    font-size: 1rem;
                    font-weight: 400;
                    color: colors.$app-black;
                    line-height: 1.75rem;
                }
            }
        }
    }

}