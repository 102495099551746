@use "../../../colors.scss";

.blog-detail-screen {
    .blog-top-container {
        padding: 4.25vw 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url('../../../assets/images/blogs_top_background.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .blog-content-area {
            padding: 120px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 1240px;

            @media (max-width: 1280px) {
                padding: 48px 20px;
                width: 100%;
            }

            .topbar-button {
                padding: 12px 24px;
                font-size: 0.85rem;
                font-weight: 600;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 8px;
                white-space: nowrap;
                cursor: pointer;
                background-color: transparent;
                color: colors.$app-white;
                border: 1px solid colors.$app-white;
                margin-bottom: 24px;
            }

            .topbar-title {
                color: colors.$app-white;
                font-size: 3rem;
                text-align: center;
                line-height: 3.75rem;
                padding: 0 20%;

                @media (max-width: 768px) {
                    font-size: 2.75rem;
                    padding: 0 10%;
                }

                @media (max-width: 425px) {
                    font-size: 2.75rem;
                    padding: 0;
                }
            }

            .topbar-date {
                margin-top: 24px;
                color: colors.$app-white;
                font-size: 1.5rem;
                text-align: center;
                line-height: 2.25rem;
                font-weight: lighter;
                padding: 0 10%;

                @media (max-width: 768px) {
                    font-size: 1.25rem;
                    padding: 0 5%;
                }

                @media (max-width: 425px) {
                    font-size: 1.25rem;
                    padding: 0;
                    line-height: 2rem;
                }
            }

            .topbar-button {
                margin-top: 48px;
            }
        }
    }

    .blog-content-wrapper {
        padding: 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1024px) {
            padding: 56px 20px;
        }

        .blog-content-container {
            width: 1240px;
            display: flex;
            flex-direction: column;
            gap: 64px;

            @media (max-width: 1280px) {
                width: 100%;
            }

            p {
                font-size: 1.15rem;
                line-height: 2rem;

                span{
                    font-weight: 500;
                }

                a{
                    color: colors.$app-blue;
                    text-decoration: underline;
                }
            }

            .title {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 16px;
            }

            .subtitle {
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: 8px;
            }

            .image-container {
                display: flex;
                justify-content: center;

                .image-wrapper {
                    max-width: 500px;

                    img {
                        width: 100%;
                    }
                }
            }

            .list {
                .list-row {
                    display: flex;
                    align-items: start;
                    gap: 8px;
                    margin-bottom: 24px;

                    .s-no {
                        font-size: 1.5rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}