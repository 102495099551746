.media-screen {
    .media-content-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-wrapper {
            display: flex;
            flex-direction: column;
            gap: 80px;
            width: 1240px;
            padding: 86px 20px;

            @media (max-width: 1280px) {
                width: 100%;
            }
        }
    }
}