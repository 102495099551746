@use "../../colors.scss" as colors;

.app-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1240px;
    z-index: 200;

    @media (max-width: 1280px) {
        width: 100%;
        padding: 0 20px;
    }

    .logo {
        width: 200px;
    }

    .menu-links {
        display: flex;
        align-items: center;
        gap: 2vw;

        .menu-item-container {
            position: relative;
            
            a {
                color: colors.$app-white;
                text-decoration: none;
            }
            
            .menu-item {
                color: colors.$app-white;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;


                .active {
                    transform: rotate(180deg) translateY(-4px);
                }
            }

            .submenu-items-container {
                width: 250px;
                position: absolute;
                top: 32px;
                left: -75px;
                background-color: colors.$app-white;
                border-radius: 12px;
                overflow: hidden;
                box-shadow: #00000029 0px 3px 6px, #0000003b 0px 3px 6px;
                z-index: 500;

                .submenu-item {
                    font-size: 1rem;
                    color: colors.$app-indigo;
                    padding: 16px;
                    border-top: 1px solid colors.$app-border-grey;

                    a {
                        color: colors.$app-indigo;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .appointment-button {
        background-color: colors.$app-blue;
        padding: 12px 20px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 20px;
        border: none;
        color: colors.$app-white;
    }

    .menu-toggle-icon {
        height: 40px;
        width: 40px;
        cursor: pointer;
        background-color: #FFFFFF44;
    }

    .sidebar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: colors.$app-dark-blue;
        z-index: 1000;

        .mobile-sidebar-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px 20px;

            .close-icon {
                background-color: colors.$app-cyan;
                padding: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                
                img {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .links-wrapper {
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 20px;

            .mobile-menu-links-container {
                border-bottom-color: #FFFFFF88;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                padding: 12px 0;
                width: 100%;
                
                .mobile-menu-link {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 8px;
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: colors.$app-white;

                    .toggleicon {
                        height: 16px;
                        width: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .active {
                        transform: rotate(180deg);
                    }
                }

                .mobile-submenu-link-container {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    gap: 12px;
                    padding: 12px 0;
                    
                    .mobile-submenu-link{
                        color: colors.$app-white;
                        font-size: 1.25rem;
                    }
                }
            }
        }

        .button-container{
            margin-top: 24px;
            width: 100%;
            display: flex;
            justify-content: center;

            button{
                height: 54px;
                width: 90%;
                display: flex;
                justify-content: center;
                font-size: 1.25rem;
            }
        }
    }
}