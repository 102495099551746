@use "../../../colors.scss";

.home-grade-security {
    background: url("../../../assets/images/grade_security_background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 48px;
    border-radius: 12px;
    position: relative;

    @media (max-width: 1024px) {
        padding: 24px 20px;
    }

    .content-area {
        width: 70%;
        min-width: 800px;
        z-index: 1;

        @media (max-width: 1024px) {
            min-width: auto;
        }

        @media (max-width: 768px) {
            width: 100%;
        }

        .title {
            font-size: 2rem;
            font-weight: 600;
            color: colors.$app-white;
        }

        .description {
            font-size: 1rem;
            font-weight: lighter;
            color: colors.$app-white;
            margin-top: 12px;
            line-height: 2rem;
        }

        .cards-container {
            display: flex;
            gap: 24px;
            margin-top: 48px;

            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: start;
            }

            @media (max-width: 450px) {
                flex-direction: column;
                align-items: center;
            }

            .card {
                display: flex;
                flex-direction: column;
                gap: 42px;
                font-size: 1.25rem;
                line-height: 2rem;
                color: colors.$app-black;
                border-radius: 8px;
                background-color: colors.$app-white;
                padding: 20px;
                font-weight: 500;

                @media (max-width: 1024px) {
                    width: 350px;
                }

                @media (max-width: 450px) {
                    width: 100%;
                    gap: 24px;
                }

                img {
                    height: 32px;
                    width: 32px;
                }
            }
        }
    }

    .background-image {
        position: absolute;
        top: 0;
        right: 0;
        height: 90%;
    }
}