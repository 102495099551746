@use "../../colors.scss";

.app-top-container {
    padding: 4.25vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .top-content-area {
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1240px;
        z-index: 100;

        @media (max-width: 1280px) {
            padding: 48px 20px;
            width: 100%;
        }

        .route-detail {
            display: flex;
            align-items: center;
            gap: 4px;
            color: colors.$app-white;
            margin-bottom: 32px;

            .seperator {
                margin-left: 4px;
            }
        }

        .topbar-title {
            color: colors.$app-white;
            font-size: 3.25rem;
            text-align: center;
            line-height: 3.75rem;
            padding: 0 20%;

            @media (max-width: 768px) {
                font-size: 2.75rem;
                padding: 0 10%;
            }

            @media (max-width: 425px) {
                font-size: 2.75rem;
                padding: 0;
            }
        }

        .topbar-description {
            margin-top: 24px;
            color: colors.$app-white;
            font-size: 1.5rem;
            text-align: center;
            line-height: 2.25rem;
            font-weight: lighter;
            padding: 0 10%;

            @media (max-width: 768px) {
                font-size: 1.25rem;
                padding: 0 5%;
            }

            @media (max-width: 425px) {
                font-size: 1.25rem;
                padding: 0;
                line-height: 2rem;
            }
        }

        .topbar-button {
            margin-top: 48px;
        }
    }
}