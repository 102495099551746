@use "../../colors.scss";

.blogs-screen {
    .blogs-content-wrapper {
        padding: 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1024px) {
            padding: 56px 20px;
        }

        .blogs-content-container {
            width: 1240px;
            display: flex;
            flex-direction: column;

            @media (max-width: 1280px) {
                width: 100%;
            }

            .cards-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 38px;
                gap: 32px;

                .card {
                    border-radius: 12px;
                    border: 1px solid colors.$app-border-grey;
                    width: 30%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;

                    @media (max-width: 768px) {
                        width: 46%;
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                    }

                    .news-image {
                        width: 100%;
                    }

                    .news-content {
                        padding: 20px;
                        flex: 1;
                        display: flex;
                        gap: 32px;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: start;

                        .news-title-date {
                            display: flex;
                            flex-direction: column;
                            gap: 12px;

                            .date {
                                color: colors.$app-dark-blue;
                                font-size: 0.75rem;
                            }

                            .news-title {
                                font-size: 1.5rem;
                                color: colors.$app-black;
                                font-weight: 600;
                                line-height: 2.25rem;

                                @media (max-width: 1024px) {
                                    font-size: 1.25rem;
                                    line-height: 2rem;
                                }

                                @media (max-width: 600px) {
                                    font-size: 1.5rem;
                                    line-height: 2rem;
                                }
                            }
                        }
                    }
                }
            }

            .pagination-container {
                margin-top: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
                font-size: 1.25rem;
                font-weight: 600;
                color: colors.$app-black;

                img{
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
}