@use "../../../colors.scss";

.about-expertise {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1240px;

        @media (max-width: 1280px) {
            width: 100%;
        }

        .title-container-title {
            color: colors.$app-black;
            font-size: 2.5rem;
            text-align: center;
            font-weight: 500;
        }

        .title-container-description {
            margin-top: 24px;
            color: colors.$app-black;
            font-size: 1.25rem;
            text-align: center;
            line-height: 40px;
            font-weight: lighter;

            @media (max-width: 425px) {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }

    .corousel-container {
        overflow-x: hidden;
        width: 1240px;
        padding: 64px 0;

        @media (max-width: 1280px) {
            width: 100%;
        }

        .scroll-container {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            width: 100%;
            gap: 42px;
            scrollbar-width: none;
            -ms-overflow-style: none;

            .corousel-card {
                padding: 24px;
                background: colors.$app-light-blue;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 32px;
                min-width: 30%;
                max-width: 30%;

                @media (max-width: 1080px) {
                    min-width: 320px;
                    max-width: 320px;
                }

                .corousel-title {
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                .corousel-description {
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 2rem;

                    @media (max-width: 425px) {
                        font-size: 0.85rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }

    .arrow-container {
        display: flex;
        align-items: center;
        gap: 48px;
        margin-top: 20px;

        .arrow {
            cursor: pointer;

            &.inactive {
                opacity: 0.5;
            }
        }

    }
}