@use "../../colors.scss";

.contact-us-screen {
    .contact-content-wrapper {
        padding: 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1024px) {
            padding: 56px 20px;
        }

        .contact-content-container {
            width: 1240px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 90px;

            @media (max-width: 1280px) {
                width: 100%;
            }

            // .form-container {
            //     width: 70%;
            //     box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
            //     border-radius: 12px;
            //     padding: 64px 48px;

            //     @media (max-width: 768px) {
            //         width: 100%;
            //     }

            //     .splitter {
            //         display: flex;
            //         align-items: center;
            //         gap: 32px;

            //         @media (max-width: 1000px) {
            //             flex-direction: column;
            //         }
            //     }

            //     .input-container {
            //         display: flex;
            //         flex-direction: column;
            //         align-items: start;
            //         gap: 8px;
            //         flex: 1;
            //         padding: 24px 0;

            //         @media (max-width: 1000px) {
            //             width: 100%;
            //         }

            //         label {
            //             font-size: 1.25rem;

            //             .required-indicator {
            //                 font-size: 0.85rem;
            //                 color: colors.$app-red;
            //             }
            //         }

            //         input, textarea,select {
            //             width: 100%;
            //             padding: 8px 12px;
            //             border-radius: 6px;
            //             border: 1px solid colors.$app-input-border;
            //             color: colors.$app-black;
            //             height: 64px;
            //             font-size: 1.25rem;
            //         }

            //         select{
            //             appearance: none;
            //             -webkit-appearance: none;
            //             -moz-appearance: none;
            //             -ms-appearance: none;
            //             background-image: url("../../assets/images/arrow_down_black.svg");
            //             background-repeat: no-repeat;
            //             background-size: 24px;
            //             background-position-x: 95%;
            //             background-position-y: 50%;
            //             background-color: colors.$app-white;
            //         }

            //         textarea{
            //             resize: none;
            //             min-height: 150px;
            //         }
            //     }

            //     button{
            //         background-color: colors.$app-blue;
            //         border: none;
            //         width: 100%;
            //         text-align: center;
            //         color: colors.$app-white;
            //         font-size: 1.15rem;
            //         font-weight: 500;
            //         padding: 12px;
            //         border-radius: 48px;
            //         cursor: pointer;
            //     }
            // }

            .ck-form {
                width: 100%;

                iframe{
                    width: 100%;
                    height: 700px;
                    border: none;

                    @media (max-width: 640px) {
                        height: 1150px;
                    }
                }
            }

        }
    }
}