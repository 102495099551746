.home-screen {

    .products-wrapper {
        padding: 0 20px;

        @media (max-width:1024px) {
            padding: 0;
        }
    }

    .home-content-wrapper {
        padding: 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1024px) {
            padding: 56px 20px;
        }

        .home-content-container {
            width: 1240px;
            display: flex;
            flex-direction: column;
            gap: 100px;

            @media (max-width: 1280px) {
                width: 100%;
            }
        }
    }
}