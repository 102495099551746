@use "../../colors.scss";

.app-footer {
    background-color: colors.$app-indigo;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-top {
        padding: 83px 20px;
        background: url("../../assets/images/footer_background.png");
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        @media (max-width: 1024px) {
            padding: 84px 20px;
        }

        .footer-title {
            color: colors.$app-white;
            font-size: 2.5rem;
            line-height: 3.5rem;
            font-weight: 500;
            text-align: center;
            width: 1080px;

            @media (max-width: 1024px) {
                width: 100%;
            }

            @media (max-width: 425px) {
                font-size: 2rem;
            }
        }
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        gap: 15%;
        padding: 100px 0;
        width: 1240px;

        @media (max-width: 1280px) {
            width: 100%;
            padding: 100px 20px;
        }

        @media (max-width: 1024px) {
            padding: 84px 20px;
            flex-direction: column;
            align-items: center;
            gap: 32px;
        }

        @media (max-width: 768px) {
            gap: 48px;
        }

        .sentellent-content {
            width: 320px;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 24px;

            @media (max-width: 1024px) {
                width: 70vw;
                gap: 16px;
                align-items: center;
            }

            @media (max-width: 425px) {
                width: 100%;
                align-items: center;
            }

            .sentellent-logo {
                width: 150px;
            }

            .sentellent-summary {
                font-size: 1rem;
                font-weight: 300;
                color: colors.$app-white;
                line-height: 1.75rem;

                @media (max-width: 1024px) {
                    text-align: center;
                }
            }
        }

        .menu-container {
            flex: 1;
            display: flex;
            gap: 60px;
            justify-content: space-between;
            width: 100%;

            @media (max-width: 1240px) {
                justify-content: center;
                gap: 120px;
            }

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 48px;
            }

            .category-container {
                display: flex;
                flex-direction: column;
                gap: 24px;

                @media (max-width: 768px) {
                    align-items: center;
                    gap: 16px;
                }

                .category-title {
                    color: colors.$app-white;
                    font-size: 1.25rem;
                    font-weight: 500;

                    @media (max-width: 425px) {
                        font-size: 1.5rem;
                    }
                }

                .menu-link {
                    font-size: 1rem;
                    color: colors.$app-white;
                    font-weight: 300;
                    text-decoration: none;

                    @media (max-width: 425px) {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }

    .copyright-wrapper {
        width: 100%;
        border-top: 1px solid colors.$app-dark-blue;
        padding: 20px;
        display: flex;
        justify-content: center;


        .copyright-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1240px;

            @media (max-width: 1280px) {
                width: 100%;
            }

            @media (max-width: 768px) {
                padding: 20px;
            }

            @media (max-width: 468px) {
                flex-direction: column;
                gap: 32px;
            }

            .copyright-title {
                color: colors.$app-white;
                font-size: 1rem;
                font-weight: 300;
            }

            .uparrow-button {
                height: 42px;
                width: 42px;
                background-color: colors.$app-cyan;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                cursor: pointer;
            }
        }
    }
}