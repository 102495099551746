@use "../../colors.scss";

.blogs-recent-news {
    .title {
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }

    .cards-container {
        display: flex;
        justify-content: space-between;
        margin-top: 38px;
        gap: 32px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: 425px) {
            flex-direction: column;
        }

        .card {
            border-radius: 12px;
            border: 1px solid colors.$app-border-grey;
            width: 30%;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                width: 350px;
            }

            @media (max-width: 425px) {
                width: 100%;
            }

            .news-image {
                width: 100%;
            }

            .news-content {
                padding: 20px;
                flex: 1;
                display: flex;
                gap: 32px;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;

                .news-title-date {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    .date {
                        color: colors.$app-dark-blue;
                        font-size: 0.75rem;
                    }

                    .news-title {
                        font-size: 1.5rem;
                        color: colors.$app-black;
                        font-weight: 600;
                        line-height: 2.25rem;

                        @media (max-width: 1024px) {
                            font-size: 1.25rem;
                            line-height: 2rem;
                        }

                        @media (max-width: 768px) {
                            font-size: 1.5rem;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}