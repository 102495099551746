@font-face {
    font-family: "IBMPlexSans";
    src: url("../src/assets/fonts/IBMPlexSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "IBMPlexSans";
    src: url("../src/assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "IBMPlexSans";
    src: url("../src/assets/fonts/IBMPlexSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "IBMPlexSans";
    src: url("../src/assets/fonts/IBMPlexSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "IBMPlexSans";
    src: url("../src/assets/fonts/IBMPlexSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}