@use "../../../../colors.scss";

.product-keyfeatures {
    .title {
        font-size: 2rem;
        font-weight: 500;
        color: colors.$app-black;
        text-align: center;
    }

    .keyfeatures-container {
        margin-top: 96px;
        display: flex;
        flex-direction: column;
        gap: 96px;

        @media (max-width: 1024px) {
            margin-top: 54px;
            gap: 54px;
        }

        .feature-row {
            display: flex;
            justify-content: center;
            gap: 48px;

            @media (min-width: 1024px) {
                &:nth-child(2n) {
                    flex-direction: row-reverse;
                }
            }

            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: center;
                gap: 24px;
            }

            .row-left-column {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: start;
                gap: 24px;

                @media (max-width: 1024px) {
                    width: 80%;
                    align-items: center;
                    gap: 16px;
                }

                @media (max-width: 425px) {
                    gap: 16px;
                    width: 100%;
                    align-items: center;
                }

                img {
                    width: 48px;
                    height: 48px;
                }

                .row-title {
                    color: colors.$app-black;
                    font-size: 2rem;
                    font-weight: 500;

                    @media (max-width: 1024px) {
                        text-align: center;
                    }

                    @media (max-width: 425px) {
                        font-size: 1.75rem;
                    }
                }

                .row-description {
                    color: colors.$app-black;
                    font-size: 1.25rem;
                    font-weight: 400;
                    line-height: 2rem;

                    @media (max-width: 425px) {
                        font-size: 1rem;
                        line-height: 1.75rem;
                    }
                }
            }

            .row-right-column {
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 1024px) {
                    width: 80%;
                }

                @media (max-width: 425px) {
                    width: 100%;
                    align-items: center;
                }

                img {
                    width: 100%;
                    max-width: 300px;
                }
            }
        }
    }
}