@use "../../colors.scss";

.app-button {
        padding: 12px 24px;
        font-size: 0.85rem;
        font-weight: 500;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 8px;
        white-space: nowrap;
        cursor: pointer;
        font-family: IBMPlexSans, sans-serif;
        
        &.filled {
                background-color: colors.$app-blue;
                color: colors.$app-white;
                border: none;
        }
        
        &.outlined {
                background-color: colors.$app-white;
                color: colors.$app-blue;
                border: 1px solid colors.$app-blue;
        }
}