@use "../../../colors.scss";

.about-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1240px;

        @media (max-width: 1280px) {
            width: 100%;
        }

        .title-container-title {
            color: colors.$app-black;
            font-size: 2.5rem;
            text-align: left;
            font-weight: 500;

            @media (max-width: 425px) {
                font-size: 2rem;
            }
        }

        .title-container-description {
            margin-top: 24px;
            color: colors.$app-black;
            font-size: 1.25rem;
            text-align: left;
            line-height: 2rem;
            font-weight: lighter;

            span{
                font-weight: 500;
            }

            @media (max-width: 425px) {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }

    .cards-container{
        display: flex;
        gap: 24px;
        margin-top: 32px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .card{
            background-color: colors.$app-light-blue;
            padding: 32px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 16px;

            .card-title{
                font-size: 1.25rem;
                color: colors.$app-black;
                font-weight: 600;
            }

            .card-description{
                font-size: 1rem;
                color: colors.$app-black;
                font-weight: 400;
                line-height: 2rem;

                span{
                    font-weight: 500;
                }
            }
        }
    }

    .map-card{
        margin-top: 24px;
        width: 100%;
        background-color: colors.$app-light-blue;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;

        img{
            width: 100%;
        }
    }

    .footer-text{
        width: 100%;
        font-size: 1.25rem;
        text-align: left;
        line-height: 2rem;
        margin-top: 24px;

        span{
            font-weight: 500;
        }
    }
}