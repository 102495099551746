@use "./fonts.scss";

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "IBMPlexSans", sans-serif;
}