@use "../../../../colors.scss";

.products-industries {
    padding: 40px 20px;
    background: colors.$app-light-blue;
    border-radius: 12px;

    @media (max-width: 1024px) {
        padding: 48px 20px;
    }

    .title {
        font-size: 2.5rem;
        color: colors.$app-black;
        font-weight: 500;
        text-align: center;

        @media (max-width: 425px) {
            font-size: 1.75rem;
        }
    }

    .scroll-container {
        width: 100%;
        min-width: 1240px;
        overflow-x: auto;
        white-space: nowrap;
        padding: 1rem;
        scrollbar-width: none;
        -ms-overflow-style: none;
        user-select: none;

        @media (max-width: 1280px) {
            min-width: auto;
        }

        .images-container {
            display: flex;
            gap: 36px;


            .image-card {
                position: relative;
                width: 350px;
                height: 300px;
                border-radius: 10px;
                overflow: hidden;
                min-width: max-content;

                &:hover {
                    .image-content {
                        transform: translateY(-50%);
                    }
                }

                @media (max-width: 525px) {
                    height: 70vw;
                    width: auto;
                }

                .image-content {
                    position: absolute;
                    height: 200%;
                    width: 100%;
                    transition: transform 0.3s ease;

                    .card-title {
                        height: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5rem;
                        color: colors.$app-white;
                        background-color: #00000033;
                    }

                    .hovered-content {
                        height: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 24px;
                        padding: 0 20px;
                        background-color: #00000066;

                        .hovered-content-title {
                            color: colors.$app-white;
                            font-size: 1.5rem;
                            white-space: wrap;
                            text-align: center;
                        }

                        .hovered-content-summary {
                            color: colors.$app-white;
                            font-size: 0.85rem;
                            line-height: 1.25rem;
                            white-space: wrap;
                            text-align: center;
                        }

                        button {
                            font-size: 0.75rem;
                            padding: 8px 12px;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            background-color: colors.$app-blue;
                            color: colors.$app-white;
                            border: none;
                            border-radius: 16px;
                            cursor: pointer;

                            img {
                                width: 16px;
                            }
                        }
                    }
                }

                .background-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .arrow-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 48px;
        margin-top: 20px;

        .arrow {
            cursor: pointer;

            &.inactive {
                opacity: 0.5;
            }
        }

    }
}