@use "../../colors.scss";

.aboutus-screen{
    .about-content-wrapper {
        padding: 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1024px) {
            padding: 56px 20px;
        }

        .about-content-container {
            width: 1240px;
            display: flex;
            flex-direction: column;

            .divider{
                width: 100%;
                height: 1px;
                background-color: colors.$app-border-grey;
                margin: 96px 0;

                @media (max-width: 768px) {
                    margin: 48px 0;
                }

            }

            @media (max-width: 1280px) {
                width: 100%;
            }
        }
    }
}